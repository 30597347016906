/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const LowSlopeAndFlatRoofsPage = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="Low Slope and flat roofs"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerBottomIconAlt="Icon with blue buildings"
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Low Slope & Flat Roofs" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Man repairing a roof."
            imageURL={data.ResidentialServices.childImageSharp.gatsbyImageData}
            titleText="Flat Roof Repair for Homes in Phoenix, Arizona"
          >
            <p>
              Legacy can repair patio roofs and standard flat roofs for
              residential homes throughout Arizona. Our knowledge of how water
              flows, typical areas of water entry and where materials can become
              damaged ensure that we’ll locate the area of your leak and get it
              fixed properly.
            </p>

            <Heading as="h2" variant="styles.h3" sx={{ mt: 6 }}>
              Repairs and Maintenance for Flat and Low-Slope Roofs
            </Heading>

            <p>
              Flat roofs (modified bitumen, TPO, foam, elastomeric, silicone)
              should be maintained in some form annually. While there are many
              material types, with varying degrees of durability, they all
              require routine care and should be cleaned off to prevent water
              saturation, pooling and build up of debris, which can cause
              additional damage and lead leaks.
            </p>

            <Heading as="h3" variant="styles.h4" sx={{ mt: 5 }}>
              What to Consider When Looking For a Flat Roof Repair:
            </Heading>

            <ol>
              <li>What types of material are being used? </li>
              <li>
                Is the area of concern in a part of the roof a trouble point?
              </li>
              <li>Are there internal signs of damage from water leaks? </li>
              <li>
                Are you finding roofing material in your yard or can you see
                visible damage to the roof?
              </li>
            </ol>

            <p>
              Some roof materials will need coatings and patching throughout
              their lifespan to extend the viability of the material. This
              preventative maintenance can ensure that you can get the full life
              out of the roof and delay the inevitable replacement, which can
              cost tens of thousands of dollars. Regular maintenance can also
              ensure proper inspection to identify trouble spots early and get
              repairs done before more serious damage occurs.
            </p>

            <Heading as="h2" variant="styles.h3" sx={{ mt: 6 }}>
              Flat Roof Materials
            </Heading>

            <p>
              Flat roofs typically consist of modified bitumen (heat applied),
              self-adhered, TPO, and several coating materials. All of these
              materials will need repair work and maintenance throughout the
              life of the product. Contact Legacy Repair today and learn how you
              can save tomorrow!
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "low-slope-and-flat-roofs-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    BuildingsIcon: file(relativePath: { eq: "buildings.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ResidentialServices: file(
      relativePath: { eq: "man-laying-roof-adhesive.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default LowSlopeAndFlatRoofsPage;
